import { useAuthSlice } from "~/store/authSlice";

export default defineNuxtRouteMiddleware((_to) => {
  if (!process.server) {
    const authStore = useAuthSlice();

    const { isAuthenticated } = storeToRefs(authStore);
    if (!isAuthenticated.value) {
      return navigateTo("/menu");
    }
  }
});
